import {Head, useIsoMemo, useIsoContext} from "isoq";
import {useQuickminApi, QuickminApiProvider} from "quickmin-api/react";
import {formatSymbol} from "../utils/js-util.js";

export default function Login() {
	let iso=useIsoContext();
	let qm=useQuickminApi();
	let links=useIsoMemo(()=>qm.getAuthUrls(iso.getUrl()));

	//console.log(links);

	if (!links)
		return;

	return (
		<div class="fixed top-0 left-0 w-full h-[100dvh] z-50 flex items-center justify-center bg-base-300">
			<div class="w-full sm:w-[640px] max-h-[100dvh] inline-flex flex-col overflow-hidden p-5 relative">
				<div class="border shadow-lg p-5 rounded-lg bg-base-100">
					<div class="text-xl font-bold text-center">
						Snapflow
					</div>
					<div class="p-5 text-center">
						Please log in to continue.
					</div>
					<div class="text-center pt-2">
						{Object.keys(links).map(linkId=>
							<a class="btn btn-primary no-animation m-2"
									href={links[linkId]}>
								Login with {formatSymbol(linkId)}
							</a>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}