export function isPlainObject(value) {
	if (typeof value!=='object' || value===null)
		return false;

	return Object.getPrototypeOf(value)===Object.getPrototypeOf({})
}

export function netTry(res, fn) {
	fn().catch(e=>{
		res.statusCode=500;
		res.end(e.message);
	});
}

export function formatSymbol(symbol, format="title") {
	if (!symbol)
		symbol="";

	symbol=String(symbol);

	switch (format) {
		case "title":
			symbol=symbol.replaceAll("_"," ").replaceAll("-"," ");
			symbol=symbol
				.split(" ")
				.map(s=>s.charAt(0).toUpperCase()+s.slice(1))
				.join(" ");

			return symbol;
	}
}

export function searchParamsFromObject(o) {
    let searchParams=new URLSearchParams();
    for (let k in o)
        searchParams.set(k,o[k]);

    return searchParams;
}

export function splitPath(pathname) {
	if (pathname===undefined)
		throw new Error("Undefined pathname");

	return pathname.split("/").filter(s=>s.length>0);
}

export function urlGetArgs(url) {
	return splitPath(new URL(url).pathname);
}

export function urlGetParams(url) {
	let u=new URL(url);
	return Object.fromEntries(u.searchParams);
}

export function parseCookie(str) {
  return (str
  .split(';')
  .map(v => v.split('='))
  .reduce((acc, v) => {
    acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
    return acc;
  }, {}));
}
