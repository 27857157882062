import {usePageStorage, usePageStorageState} from "../utils/use-storage.jsx";

export function ModalAlert() {
	let alert=usePageStorage("alert");
	if (alert) {
		async function onButtonClick(handler) {
			let result;
			if (handler)
				result=await handler();

			alert.onComplete(result);
		}

		return (
			<div className="modal modal-open">
				<div className="modal-box">
					<h3 className="font-bold text-lg">{alert.title}</h3>
					<p className="py-4">{alert.content}</p>
					<div className="modal-action">
						{alert.buttons.map(b=>
							<button className={`btn ${b.class}`}
									onclick={()=>onButtonClick(b.onclick)}>
								{b.text}
							</button>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export function useModalAlert() {
	let [alert,setAlert]=usePageStorageState("alert");
	return (function(conf) {
		return new Promise(resolve=>{
			conf.onComplete=(result)=>{
				setAlert();
				resolve(result);
			}
			setAlert(conf);
		});
	});
}
