import {useState, useCallback, useLayoutEffect, useRef} from "react";

export function useEventListener(o, ev, fn) {
	useLayoutEffect(()=>{
		o.addEventListener(ev,fn);
		return ()=>{
			o.removeEventListener(ev,fn);
		}
	},[o,ev,fn]);
}

export function useRefEventListener(ref, ev, fn) {
	useLayoutEffect(()=>{
		let o=ref.current;
		o.addEventListener(ev,fn);
		return ()=>{
			o.removeEventListener(ev,fn);
		}
	},[ref,ref.current,ev,fn]);
}

export function useEventUpdate(o, ev) {
	let [_,setDummyState]=useState();
	let forceUpdate=useCallback(()=>setDummyState({}));
	useEventListener(o,ev,forceUpdate);
}

export function useIsValueChanged(value) {
	let ref=useRef();
	if (ref.current!==value) {
		ref.current=value;
		return true;
	}

	return false;
}

export function useOrientation() {
	if (typeof window=="undefined")
		return;

	function getOrientation() {
		if (window.innerHeight>window.innerWidth)
			return "portrait";

		return "landscape";
	}

	let [orientation,setOrientation]=useState(getOrientation());
	useEventListener(window,"resize",()=>{
		setOrientation(getOrientation());
	});

	return orientation;
}

export function useResizeObserver(ref, fn) {
	useLayoutEffect(()=>{
		let resizeObserver=new ResizeObserver(fn);
		resizeObserver.observe(ref.current);
		return (()=>{
			resizeObserver.disconnect();
		});
	},[ref,ref.current]);
}

export function useIsVisible(ref, threshold=1.0) {
	let [visible,setVisible]=useState(false);
	useLayoutEffect(()=>{
		function fn(entries) {
			setVisible(entries[0].isIntersecting);
		}
		let intersectionObserver=new IntersectionObserver(fn,{
			root: null,
			threshold: threshold
		});
		intersectionObserver.observe(ref.current);
		return (()=>{
			intersectionObserver.disconnect();
		});
	},[ref,ref.current]);

	return visible;
}

export function withCurrentRef(ref, fn) {
	if (typeof window=="undefined")
		return;

	if (ref.current)
		fn();

	else
		setTimeout(fn,0);
}

export function useInterval(fn, time) {
	useLayoutEffect(()=>{
		let intervalId=setInterval(fn,time);
		return ()=>{
			clearInterval(intervalId);
		}
	},[fn,time]);
}

export function PromiseButton({children, onclick, onerror, element, spin, ...props}) {
	let [busy,setBusy]=useState(false);
	let Element=element?element:"button";

	async function clickHandler(ev) {
		if (busy)
			return;

		if (onclick) {
			setBusy(true);
			try {
				await onclick(ev);
			}

			catch (e) {
				console.error(e);
				if (onerror)
					onerror(e);
			}

			setBusy(false);
		}
	}

	return (
		<Element {...props} onclick={clickHandler}>
			{busy && spin}
			{children}
		</Element>
	)
}

export function requiredHighlight(cls) {
	return ({
		required: true,
		oninvalid: (e)=>{e.target.classList.add(cls)},
		onfocus: (e)=>{e.target.classList.remove(cls)}
	});
}