import {Head, useIsoMemo, useIsoContext} from "isoq";
import {PageStorageProvider, usePageStorage, usePageStorageState} from "../utils/use-storage.jsx";
import {useQuickminApi, QuickminApiProvider} from "quickmin-api/react";
import {formatSymbol} from "../utils/js-util.js";
import {useModalAlert} from "./modal.jsx";
import {PromiseButton} from "../utils/react-util.jsx";
import {useState} from "react";
import urlJoin from "url-join";
import {useQuickRpc} from "fullstack-utils/use-quick-rpc";

function TokenItem({token, ondisconnect}) {
	let iso=useIsoContext();
	let origin=new URL(iso.getUrl()).origin;

	return (<>
		<div class="mb-5 p-5 border shadow">
			<div class="relative h-8">
				<span class="text-xl font-bold">{formatSymbol(token.provider)}</span>
				{token.name &&
					<span class="ms-2">({token.name})</span>
				}
				<div class="absolute right-0 top-0 w-full text-right">
					{token.token && <>
						<p class="inline-block me-5">Connected {token.stamp}</p>
						{token.installUrl &&
							<PromiseButton class="btn btn-warning btn-sm"
									onclick={ondisconnect}
									spin={<span class="loading loading-spinner loading-xs"></span>}>
								Disconnect
							</PromiseButton>
						}
					</>}

					{!token.token && <>
						<p class="inline-block me-5">Not Connected</p>
						{token.installUrl &&
							<a class="btn btn-primary btn-sm"
									href={token.installUrl}>
								Connect
							</a>
						}
					</>}
				</div>
			</div>
		</div>
	</>);
}

function TokenList({tokens, ondisconnect}) {
	return tokens.map(token=>
		<TokenItem
				token={token}
				ondisconnect={()=>ondisconnect(token)}/>
	);
}

export default function Integrations() {
	let [user,setUser]=usePageStorageState("user");
	let qm=useQuickminApi();
	let rpc=useQuickRpc();
	let [counter,setCounter]=useState(1);
	let tokens=useIsoMemo(()=>rpc.getMyTokens(),[counter]);
	let modalAlert=useModalAlert();

	function onLogoutClick() {
		window.document.cookie="qmtoken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
		setUser(null);
	}

	async function onDisconnectClick(token) {
		let result=await modalAlert({
			title: "Confirm",
			content: `Are you sure you want to disconnect the integration?`,
			buttons: [
				{text: "Cancel", class: "btn-primary"},
				{text: "Ok", class: "btn-primary", onclick: ()=>true}
			]
		});

		if (result) {
			await qm.update("my_tokens",token.id,{
				token: null
			});
			setCounter(counter+1);
		}
	}

	return (<>
		<div class="navbar bg-primary text-primary-content">
			<div class="navbar-start">
				<p class="text-xl font-bold me-5 ms-2">Snapflow</p>
				<a class="btn btn-sm btn-primary no-animation">My Integrations</a>
			</div>
			<div class="navbar-end">
				<btn class="btn btn-sm btn-primary no-animation"
						onclick={onLogoutClick}>
					Logout
				</btn>
			</div>
		</div>
		<div class="p-10">
			<p class="text-xl font-bold pb-5">Welcome, {user.name}</p>
			<p class="pb-10">
				Connect your acconts below to use Snapfrozen's integrations.
			</p>
			{tokens && 
				<TokenList 
						tokens={tokens}
						ondisconnect={onDisconnectClick}/>
			}
		</div>
	</>)
}
