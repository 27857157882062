import {createContext, useContext, useRef} from "react";
import {useEventUpdate} from "./react-util.jsx";

class Storage extends EventTarget {
	constructor(initial={}) {
		super();
		this.data=initial;
	}

	getItem(key) {
		return this.data[key];
	}

	setItem(key, value) {
		this.data[key]=value;
		this.dispatchEvent(new Event(key));
	}
}

let PageStorageContext=createContext();

export function PageStorageProvider({children, initial}) {
	let ref=useRef();
	if (!ref.current)
		ref.current=new Storage(initial);

	return (
		<PageStorageContext.Provider value={ref.current}>
			{children}
		</PageStorageContext.Provider>
	);
}

export function usePageStorage(key, def) {
	let pageStorage=useContext(PageStorageContext);
	useEventUpdate(pageStorage,key);
	let item=pageStorage.getItem(key);
	if (!item)
		item=def;

	return item;
}

export function usePageStorageSetter(key) {
	let pageStorage=useContext(PageStorageContext);
	return (value=>{
		pageStorage.setItem(key,value)
	});
}

export function usePageStorageState(key, def) {
	return [usePageStorage(key,def),usePageStorageSetter(key)];
}

class LocalStorage extends EventTarget {
	constructor() {
		super();
	}

	getItem(key) {
		if (typeof window=="undefined")
			return;

		try {
			let itemJson=window.localStorage.getItem(key);
			let item=JSON.parse(itemJson);
			return item;
		}

		catch (e) {
			console.log("WARNING! Unable to parse local storage item.")
			console.error(e);
		}
	}

	setItem(key, value) {
		if (typeof window=="undefined")
			return;

		window.localStorage.setItem(key,JSON.stringify(value));
		this.dispatchEvent(new Event(key));
	}
}

let LocalStorageContext=createContext(new LocalStorage());

export function useLocalStorage(key, def) {
	let localStorage=useContext(LocalStorageContext);
	useEventUpdate(localStorage,key);
	let item=localStorage.getItem(key);
	if (!item)
		item=def;

	return item;
}

export function useLocalStorageSetter(key) {
	let localStorage=useContext(LocalStorageContext);
	return (value=>{
		localStorage.setItem(key,value)
	});
}

export function useLocalStorageState(key, def) {
	return [useLocalStorage(key,def),useLocalStorageSetter(key)];
}
