import {Head, useIsoMemo, useIsoContext} from "isoq";
import {useQuickminApi, QuickminApiProvider} from "quickmin-api/react";
import {useEffect, useState} from "react";
import {parseCookie,formatSymbol, urlGetParams, searchParamsFromObject} from "../utils/js-util.js";

export default function CliLogin() {
	let iso=useIsoContext();
	let params=urlGetParams(iso.getUrl());
	let [message,setMessage]=useState(<>Logging in your CLI...</>);

	useEffect(()=>{
		(async ()=>{
			if (iso.isSsr())
				return;

			let token=parseCookie(document.cookie).qmtoken;
			console.log("sending token to cli: "+token);
			let response=await fetch(`http://localhost:${params.clilogin}`,{
				method: "POST",
				body: searchParamsFromObject({token: token})
			});

			if (response.status==200) {
				setMessage(<>
					Your snapflow CLI has stored your credentials.<br/><br/>You can close this window.
				</>);
			}

			else {
				console.log(response);
				setMessage(<>
					Something went wrong, status={response.status}
				</>);
			}
		})();
	},[])


	if (iso.isSsr())
		return;

	return (
		<div class="fixed top-0 left-0 w-full h-[100dvh] z-50 flex items-center justify-center bg-base-300">
			<div class="w-full sm:w-[640px] max-h-[100dvh] inline-flex flex-col overflow-hidden p-5 relative">
				<div class="border shadow-lg p-5 rounded-lg bg-base-100">
					<div class="text-xl font-bold text-center">
						Snapflow CLI
					</div>
					<div class="pt-5 px-5 text-center">
						{message}
					</div>
				</div>
			</div>
		</div>
	);
}