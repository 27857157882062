import Login from "./Login.jsx";
import CliLogin from "./CliLogin.jsx";
import Integrations from "./Integrations.jsx";
import {Head, useIsoMemo, useIsoContext} from "isoq";
import {PageStorageProvider, usePageStorage, usePageStorageState} from "../utils/use-storage.jsx";
import {useQuickminApi, QuickminApiProvider} from "quickmin-api/react";
import {QuickRpcProvider} from "fullstack-utils/use-quick-rpc";
import {formatSymbol, urlGetParams} from "../utils/js-util.js";
import {ModalAlert} from "./modal.jsx";

function Main() {
	let iso=useIsoContext();
	let user=usePageStorage("user");
	if (!user)
		return (
			<Login/>
		);

	let params=urlGetParams(iso.getUrl());
	if (params.clilogin)
		return (
			<CliLogin/>
		);

	return (
		<Integrations/>
	);
}

export default function({user}) {
	let iso=useIsoContext();

	return (<>
		<Head>
			<link rel="stylesheet" href="/index.css"/>
		</Head>
		<QuickminApiProvider url="/admin" fetch={iso.fetch}>
			<PageStorageProvider initial={{user: user}}>
				<QuickRpcProvider url="/quickrpc" fetch={iso.fetch}>
					<ModalAlert/>
					<Main/>
				</QuickRpcProvider>
			</PageStorageProvider>
		</QuickminApiProvider>
	</>);
}
